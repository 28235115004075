body {
  font-size: 14px;
}

html [type='button'] {
  -webkit-appearance: none;
}

.alert.alert-warning {
  background: #fcf8e3;
}

a {
  color: #007eb5;
}

a:hover {
  color: #006490;
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-primary {
  background: #007eb5;
  color: #ffffff;
  border-color: #007eb5;
}

.btn-primary:hover {
  color: #fff;
  background-color: #006490;
  border-color: #006490;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #007eb5;
  border-color: #007eb5;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #007eb5;
  border-color: #000;
}

.btn-outline-primary {
  color: #007eb5;
  background-color: transparent;
  background-image: none;
  border-color: #007eb5;
}

.btn-outline-primary:hover {
  color: #006490;
  background-color: transparent;
  border-color: #006490;
}

body {
  background: #fafafa;
}

label {
  font-weight: bold;
}
